import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AWS from 'aws-sdk';
import { useParams, useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp, faArrowTrendDown, faMoneyBill, faBuildingColumns, faHourglass, faBolt, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './EditCardPage.css';
import '../../assets/global.css';

// Initialize AWS SDK with your credentials
AWS.config.update({
    accessKeyId: "AKIAYS2NSE5D5BQS2V7W",
    secretAccessKey: "wSGiQEMD2GZ1aXSfywO2XhJ1c/v2Xk0rH8HQX3vz",
    region: 'eu-north-1',
});

const s3 = new AWS.S3();

function EditCardPage() {
    const { cardId } = useParams();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [card, setCard] = useState(null);
    const [formStatus, setFormStatus] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isActive, setIsActive] = useState('1');
    const [isSwing, setIsSwing] = useState('1');
    const [tradeType, setTradeType] = useState('1');
    const [tradeDirection, setTradeDirection] = useState('buy');
    const [images, setImages] = useState([]);
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        const fetchCardData = async () => {
            try {
                const headers = { Authorization: `Bearer ${token}` };
                const response = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/signals/${cardId}`, { headers });
                const cardData = response.data;

                const imagesUrlsValues = JSON.parse(cardData.imageUrl);
                const takeProfits = cardData.takeProfit ? JSON.parse(cardData.takeProfit).map(value => ({ value: value !== null ? value.toString() : '[0]' })) : [];

                setCard({
                    ...cardData,
                    takeProfits: takeProfits,
                });
                setImages(imagesUrlsValues);
                setIsActive(cardData.isActive === 1 ? '1' : '0');
                setIsSwing(cardData.isSwing === 1 ? '1' : '0');
                setTradeType(cardData.type === '1' ? '1' : '2');
                setTradeDirection(cardData.tradeDirection === 'buy' ? 'buy' : 'sell');
            } catch (error) {
                console.error(error.response.data);
                setFormStatus({ status: 'error', message: 'Failed to fetch card data.' });
            }
        };
        fetchCardData();
    }, [cardId, token]);

    const handleRegularInputChange = (e) => {
        setCard({ ...card, [e.target.name]: e.target.value });
        setIsDirty(true);
    };

    const handleTakeProfitChange = (index, event) => {
        const newTakeProfits = card.takeProfits.slice();
        newTakeProfits[index] = { value: event.target.value };
        setCard({ ...card, takeProfits: newTakeProfits });
        setIsDirty(true);
    };

    const handleAddTakeProfit = () => {
        setCard({ ...card, takeProfits: [...card.takeProfits, { value: '' }] });
        setIsDirty(true);
    };

    const handleRemoveTakeProfit = (index) => {
        const newTakeProfits = card.takeProfits.slice();
        newTakeProfits.splice(index, 1);
        setCard({ ...card, takeProfits: newTakeProfits });
        setIsDirty(true);
    };

    const handleDeleteCard = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete this card?');
        if (!confirmDelete) {
            return;
        }
        try {
            const headers = { Authorization: `Bearer ${token}` };
            await axios.put(
                `https://trading-website-5b1479c37ccb.herokuapp.com/deleteCard/${cardId}`,
                {}, 
                { headers } 
            );
            setFormStatus({ status: 'success', message: 'Card deleted successfully!' });
            navigate('/dashboard');
        } catch (error) {
            console.error('Error during Axios operation', error);
            setFormStatus({ status: 'error', message: 'Could not delete card' });
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);


        const takeProfitValues = card.takeProfits.map(tp => parseFloat(tp.value));
        const updatedImages = images.map(image => image.data ? image.data : image);
        const updatedCardData = {
            ...card,
            takeProfits: JSON.stringify(takeProfitValues),
            imagesUrl: JSON.stringify(updatedImages),
            isActive: isActive,
            isSwing: isSwing,
            tradeDirection: tradeDirection,
            type: tradeType,
            exitPrice: card.exitPrice ? parseFloat(card.exitPrice) : 0,
        };


        if (isDirty) {
            const confirmChanges = window.confirm('Do you want to accept the following changes? ' + JSON.stringify(updatedCardData, null, 2));
            if (!confirmChanges) {
                setLoading(false);
                return;
            }
        }

        try {
            const headers = { Authorization: `Bearer ${token}` };
            const response = await axios.put(`https://trading-website-5b1479c37ccb.herokuapp.com/updateCard/${cardId}`, updatedCardData, { headers });

            if (response.status >= 200 && response.status < 300) {
                const responseMsg = 'Card updated successfully!';
                const isConfirmed = window.confirm(responseMsg + ' Click OK to continue.');
                if (isConfirmed) {
                    setFormStatus({ status: 'success', message: responseMsg });
                    setIsDirty(false);
                    setImages([]);
                    navigate('/dashboard');
                }
            } else {
                throw new Error('Failed to update card');
            }
        } catch (error) {
            console.error('Error during Axios operation', error.response ? error.response.data : error.message);
            const errorMsg = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Could not update card data';
            window.alert(errorMsg);
            setFormStatus({ status: 'error', message: errorMsg });
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = async (files) => {
        const newImages = Array.from(files).map(async (file) => {
            const params = {
                Bucket: 'trading-website-bucket',
                Key: file.name,
                Body: file,
                ACL: 'public-read',
            };
            const data = await s3.upload(params).promise();
            return {
                Location: URL.createObjectURL(file),
                data: data
            };

        });

        Promise.all(newImages)
            .then((uploadedImages) => {
                setImages((prevImages) => [...prevImages, ...uploadedImages]);
                setIsDirty(true);
            })
            .catch((error) => {
                console.error('Error uploading images:', error);
            });
    };

    const handleDeleteImage = (index) => {
        const newImages = [...images];
        const params = {
            Bucket: 'trading-website-bucket',
            Key: newImages[index]["Key"],
        };
        s3.deleteObject(params, (err, data) => {
            if (err) {
                console.error("Error deleting object:", err);
            } else {
                newImages.splice(index, 1);
                setImages(newImages);
                setIsDirty(true);
            }
        });
    };



    const handleCloseTrade = async () => {
        const confirmClose = window.confirm('Are you sure you want to close this trade?');
        if (!confirmClose) {
            return;
        }
    
        const exitPrice = prompt('Enter exit price:');
        if (exitPrice !== null) {
            const parsedExitPrice = parseFloat(exitPrice);
            if (!isNaN(parsedExitPrice)) {
                setCard((prevCard) => ({ ...prevCard, exitPrice: parsedExitPrice }));
                setIsActive('0');
                setIsDirty(true);
                await submitTradeClosure(parsedExitPrice);
            } else {
                window.alert('Please enter a valid exit price.');
            }
        }
    };
    
    const submitTradeClosure = async (exitPrice) => {
        setLoading(true);

        const takeProfitValues = card.takeProfits.map(tp => parseFloat(tp.value));
        const updatedImages = images.map(image => image.data ? image.data : image);
    
        const updatedCardData = {
            ...card,
            takeProfits: JSON.stringify(takeProfitValues),
            imagesUrl: JSON.stringify(updatedImages),
            isActive: 0,
            exitPrice: exitPrice,
        };
    
        try {
            const headers = { Authorization: `Bearer ${token}` };
            const response = await axios.put(`https://trading-website-5b1479c37ccb.herokuapp.com/updateCard/${cardId}`, updatedCardData, { headers });
    
            if (response.status >= 200 && response.status < 300) {
                const responseMsg = 'Trade closed successfully!';
                window.alert(responseMsg);
                setFormStatus({ status: 'success', message: responseMsg });
                setIsDirty(false);
                navigate('/dashboard');
            } else {
                throw new Error('Failed to close trade');
            }
        } catch (error) {
            console.error('Error during Axios operation', error.response ? error.response.data : error.message);
            const errorMsg = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Could not close trade';
            window.alert(errorMsg);
            setFormStatus({ status: 'error', message: errorMsg });
        } finally {
            setLoading(false);
        }
    };

    if (!card) return <div className="spinner">Loading...</div>;  
    if (formStatus && formStatus.status === 'error') return <div>Error: {formStatus.message}</div>;
    

    return (
        <div className="edit-card-container">
            <h1>Edit Trading Card</h1>
            {formStatus && <p className={`form-status ${formStatus.status}`}>{formStatus.message}</p>}

            <form onSubmit={handleSubmit} className="edit-card-form">
                <label>
                    Pair:
                    <input type="text" name="pair" value={card.pair} onChange={handleRegularInputChange} required />
                </label>
                <label>
                    Entry Price:
                    <input type="number" name="entryPrice" value={card.entryPrice} onChange={handleRegularInputChange} />
                </label>
                <label>
                    Stop Loss:
                    <input type="number" name="stopLoss" value={card.stopLoss} onChange={handleRegularInputChange} />
                </label>

                <label>Take Profits:</label>
                {card.takeProfits.map((takeProfit, index) => (
                    <div className="take-profit-input" key={index}>
                        <input
                            type="number"
                            name={`takeProfit-${index}`}
                            value={takeProfit.value}
                            
                            onChange={(e) => handleTakeProfitChange(index, e)}
                        />
                        <button type="button" onClick={() => handleRemoveTakeProfit(index)} className='add-take-profit'>
                            Remove
                        </button>
                    </div>
                ))}
                <button type="button" onClick={handleAddTakeProfit} className='add-take-profit'>
                    Add Take Profit
                </button>

                <div className="icon-group">
                    <button
                        type="button"
                        className={`select-button ${tradeType === '1' ? 'active' : ''}`}
                        onClick={() => setTradeType('1')}
                    >
                        <FontAwesomeIcon icon={faMoneyBill} title="Forex" />
                    </button>
                    <button
                        type="button"
                        className={`select-button ${tradeType === '2' ? 'active' : ''}`}
                        onClick={() => setTradeType('2')}
                    >
                        <FontAwesomeIcon icon={faBuildingColumns} title="Indices" />
                    </button>
                </div>

                <div className="icon-group">
                    <button
                        type="button"
                        className={`select-button ${tradeDirection === 'buy' ? 'active' : ''}`}
                        onClick={() => setTradeDirection('buy')}
                    >
                        <FontAwesomeIcon icon={faArrowTrendUp} title="Buy Signal" />
                    </button>
                    <button
                        type="button"
                        className={`select-button ${tradeDirection === 'sell' ? 'active' : ''}`}
                        onClick={() => setTradeDirection('sell')}
                    >
                        <FontAwesomeIcon icon={faArrowTrendDown} title="Sell Signal" />
                    </button>
                </div>

                <div className="icon-group">
                    <button
                        type="button"
                        className={`select-button ${isActive === '1' ? 'active' : ''}`}
                        onClick={() => {
                            setIsActive('1');
                            setIsDirty(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faCheckCircle} title="Active" />
                    </button>
                    <button
                        type="button"
                        className={`select-button ${isActive === '0' ? 'active' : ''}`}
                        onClick={() => {
                            setIsActive('0');
                            setIsDirty(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} title="Inactive" />
                    </button>
                </div>

                
                <div className="icon-group">
                    <button
                        type="button"
                        className={`select-button ${isSwing === '1' ? 'active' : ''}`}
                        onClick={() => { 
                            setIsSwing('1'); 
                            setIsDirty(true); 
                        }}
                        
                    >
                        <FontAwesomeIcon icon={faHourglass} title="Long Term" />
                    </button>
                    <button
                        type="button"
                        className={`select-button ${isSwing === '0' ? 'active' : ''}`}
                        onClick={() => {
                            setIsSwing('0');
                            setIsDirty(true);
                        }}
                    >
                        <FontAwesomeIcon icon={faBolt} title="Short Term" />
                    </button>
                </div>
                


                <div className="image-upload-container">
                    <div className="image-preview-container">
                        {images.map((image, index) => (
                            <div key={index} className="image-preview">
                                <img src={image["Location"]} alt={`Preview ${index}`} />
                                <button type='button' onClick={() => handleDeleteImage(index)}>Delete</button>
                            </div>
                        ))}
                    </div>

                    <div className="drag-drop-area">
                        <input type="file" accept="image/*" onChange={(e) => handleImageChange(e.target.files)} multiple />
                        <p>Drag & Drop Images Here</p>
                    </div>
                </div>

                <label>
                    Admin Comment:
                    <textarea name="adminComment" value={card.adminComment} onChange={handleRegularInputChange} ></textarea>
                </label>
                <button type="submit" disabled={!isDirty}>Save Changes</button>
                <button type="button" onClick={handleCloseTrade} style={{ backgroundColor: "green" }}>Close Trade</button>
                <button type="button" onClick={handleDeleteCard} style={{ backgroundColor: "red" }}>Delete Trade</button>
                <button type="button" onClick={() => navigate('/dashboard')} className='add-take-profit'>Cancel</button>
                {loading && (
                    <div className="loading-spinner">
                        <ThreeDots type="ThreeDots" color="#00BFFF" height={80} width={80} />
                    </div>
                )}

            </form>

        </div>
    );
}

export default EditCardPage;