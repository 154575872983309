import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PaymentPage.css';
import '../../assets/global.css';

function PaymentPage() {

    const [userData, setUserData] = useState([]);

    const userId = sessionStorage.getItem('userId');
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        const fetchUserEmail = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`
                };

                const userDataResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/getUserData`, { headers });
                setUserData(userDataResponse.data.user.email);

            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };



        fetchUserEmail();
    }, [userId, token]);

    useEffect(() => {

        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);


    return (
        <div>
            <stripe-pricing-table
                pricing-table-id="prctbl_1QfpiTIJwLf9B0hf0lBrvEWQ"
                publishable-key="pk_live_51OAaxzIJwLf9B0hfsi9Yh8fdNncgZkXkcZqZGjbSrsYK6QY2Kci3Q3jDMuZi1dFKI7OcJVH88R7jfcXnZgwMfpPP008fQtImVT"
                customer-email={userData}
            >
            </stripe-pricing-table>
        </div>
    );
}

export default PaymentPage;
