import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './ForgotPasswordPage.css';

function ForgotPasswordPage() {
    const [email, setEmail] = useState('');
    const [formStatus, setFormStatus] = useState(null);
    const [noVerificationCode, setNoVerificationCode] = useState(true);
    const [verificationCode, setVerificationCode] = useState(Array(6).fill(""));

    const handleCodeChange = (e, index) => {
        const { value } = e.target;
    
        if (/^[0-9]?$/.test(value)) { 
            const newCode = [...verificationCode];
            newCode[index] = value;
            setVerificationCode(newCode);
    
            if (index < 5 && value) {
                document.getElementById(`code-${index + 1}`).focus();
            }
        }
    };
    

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && index > 0 && !verificationCode[index]) {
            document.getElementById(`code-${index - 1}`).focus();
        }
    };

    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        const code = verificationCode.join('');
        console.log(code);
        
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const response = await fetch('https://trading-website-5b1479c37ccb.herokuapp.com/forgotPassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            const data = await response.json();
            console.log('Forgot Password Request Success', data);
            setFormStatus({ status: 'success', message: 'Password reset instructions sent to your email.' });
            setNoVerificationCode(false);
        } catch (error) {
            console.error('Error during forgot password request', error);
            setFormStatus({ status: 'error', message: 'There was an error processing your request. Please try again later.' });
            setNoVerificationCode(false);

        }
    };

    return (
        <div className="forgot-password-container">
            {
                noVerificationCode ? (
                    <div className="forgot-password-card">
                        <h1>Reset Password</h1>
                        {formStatus && <p className={`form-status ${formStatus.status}`}>{formStatus.message}</p>}
                        <form className="forgot-password-form" onSubmit={handleSubmit}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='forgot-password-input'
                                placeholder='Enter your email'
                                required />
                            <button type="submit" className="forgot-password-button">Submit</button>
                        </form>
                        <p className="login-text">Remember your password? <NavLink to="/login" activeClassName="active-link">Log in</NavLink></p>
                    </div>
                ) : (
                    <div className="forgot-password-card">
                        <h1>Enter Verification Code</h1>
                        {formStatus && <p className={`form-status ${formStatus.status}`}>{formStatus.message}</p>}
                        <form className="forgot-password-form" onSubmit={handleVerificationSubmit}>
                            <label htmlFor="verificationCode">Verification Code</label>
                            <div className="verification-code-inputs">
                                {[...Array(6)].map((_, index) => (
                                    <input
                                        key={index}
                                        id={`code-${index}`}
                                        type="text"
                                        maxLength="1"
                                        className="verification-input"
                                        value={verificationCode[index] || ""}
                                        onChange={(e) => handleCodeChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        required
                                    />
                                ))}
                            </div>
                            <button type="submit" className="forgot-password-button">Submit Code</button>
                        </form>
                        <p className="login-text">Remember your password? <NavLink to="/login" activeClassName="active-link">Log in</NavLink></p>
                    </div>
                )
            }

        </div>
    );
}

export default ForgotPasswordPage;
