import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewTrader.css';
import '../../assets/global.css';

function NewTrader() {
    const [faqData, setFaqData] = useState([]);
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        // Fetch program details, FAQ, and testimonials
        axios.get('/api/newTraderDetails')
            .then(response => {
                setFaqData(response.data.faq);
                setTestimonials(response.data.testimonials || []);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div className="new-trader-container">
            <div className="hero-trader-section">
                <h1>Become a Trader with Us!</h1>
                <p>Your journey to professional trading starts here.</p>
                <button onClick={() => alert('Joining Program...')}>Join Now</button>
            </div>

            <div className="section">
                <h2>Program Highlights</h2>
                <div className="cards">
                    <div className="card">
                        <h3>Learn and Grow</h3>
                        <p>Take on real-world trading challenges and improve your skills.</p>
                    </div>
                    <div className="card">
                        <h3>Get Funded</h3>
                        <p>Demonstrate your abilities and receive funding for live trading.</p>
                    </div>
                    <div className="card">
                        <h3>Profit Sharing</h3>
                        <p>Earn a share of the profits from successful trades.</p>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2>Program Benefits</h2>
                <div className="cards">
                    <div className="card">
                        <h3>Flexible Schedule</h3>
                        <p>Work at your own pace and fit trading into your lifestyle.</p>
                    </div>
                    <div className="card">
                        <h3>Access Premium Tools</h3>
                        <p>Gain access to cutting-edge trading platforms and analytics.</p>
                    </div>
                    <div className="card">
                        <h3>Exclusive Community</h3>
                        <p>Connect with like-minded traders and industry professionals.</p>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2>Testimonials</h2>
                <div className="testimonials">
                    {testimonials.length > 0 ? (
                        testimonials.map((testimonial, index) => (
                            <div key={index} className="testimonial-card">
                                <p>"{testimonial.quote}"</p>
                                <h4>- {testimonial.name}</h4>
                            </div>
                        ))
                    ) : (
                        <p>Loading testimonials...</p>
                    )}
                </div>
            </div>

            <div className="section">
                <h2>FAQ</h2>
                <div className="faq">
                    {faqData.length > 0 ? (
                        faqData.map((faq, index) => (
                            <div key={index}>
                                <h3>{faq.question}</h3>
                                <p>{faq.answer}</p>
                            </div>
                        ))
                    ) : (
                        <p>Loading FAQ...</p>
                    )}
                </div>
            </div>

            <div className="get-started-section">
                <h2>Ready to Start?</h2>
                <p>Join today and take the first step toward financial freedom.</p>
                <button onClick={() => alert('Sign Up Now')}>Sign Up</button>
            </div>

            <footer>
                <p>&copy; 2024 Your Trading Platform. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default NewTrader;
