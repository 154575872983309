import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './CardViewPage.css';
import '../../assets/global.css';
import { FaClock, FaMoneyBillWave, FaChartLine, FaComments } from 'react-icons/fa';

function CardViewPage() {
    let { cardId } = useParams();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [card, setCard] = useState(null);
    const [error, setError] = useState(null);
    const token = sessionStorage.getItem('token');

    const fetchCardData = useCallback(async () => {
        try {
            const headers = { Authorization: `Bearer ${token}` };
            const response = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/signals/${cardId}`, { headers });
            setCard(response.data);
            setError(null);
        } catch (error) {
            setError(error.response.data);
        }
    }, [token, cardId]);

    useEffect(() => {
        fetchCardData();
    }, [cardId, fetchCardData]);

    const takeProfits = card ? JSON.parse(card.takeProfit) : [];
    const imageUrls = card && card.imageUrl ? JSON.parse(card.imageUrl) : [];

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1));
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
    };

    const handleImageClick = (index) => {
        setFullscreenImage(index);
    };

    const handleCloseFullscreen = () => {
        setFullscreenImage(null);
    };

    const handleNextFullscreenImage = () => {
        handleNextImage();
        setFullscreenImage(currentImageIndex);
    };

    const handlePrevFullscreenImage = () => {
        handlePrevImage();
        setFullscreenImage(currentImageIndex);
    };

    const formatDate = (dateString) => {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const calculateTradeDuration = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start.getTime() === end.getTime()) {
            return "just opened";
        }

        const durationMs = end - start;

        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));

        let durationStr = '';
        if (days > 0) durationStr += `${days} day${days > 1 ? 's' : ''}, `;
        if (hours > 0) durationStr += `${hours} hour${hours > 1 ? 's' : ''}, `;
        if (minutes > 0) durationStr += `${minutes} minute${minutes > 1 ? 's' : ''}`;

        return durationStr.trim().replace(/,\s*$/, '');
    };

    const isTradeActive = card && card.isActive === 1;
    const isTradeClosed = card && card.exitPrice !== 0;

    const isStopLossTriggered =
        (card && card.exitPrice !== 0 && card.tradeDirection === 'buy' && card.exitPrice <= card.stopLoss) ||
        (card && card.exitPrice !== 0 && card.tradeDirection === 'sell' && card.exitPrice >= card.stopLoss);

    const calculatePips = (entryPrice, exitPrice, tradeDirection) => {
        let pips = 0;
        if (tradeDirection === 'buy') {
            pips = (exitPrice - entryPrice);
        } else if (tradeDirection === 'sell') {
            pips = (entryPrice - exitPrice);
        }
        return pips;
    };

    const now = new Date().toISOString();
    const pipsOrPointsGained = card && calculatePips(card.entryPrice, card.exitPrice, card.tradeDirection);

    


    return (
        <div className="card-view-container">
            {error ? (
                <div className="error-message">{error}</div>
            ) : (
                card ? (
                    <>
                        <div className="header-section">
                            <h1 className="card-title">{card.pair}</h1>
                            {imageUrls.length > 0 && (
                                <>
                                    {fullscreenImage !== null ? (
                                        <div className="fullscreen-image-container">
                                            <img src={imageUrls[fullscreenImage].Location} alt={`Preview ${fullscreenImage}`} onClick={handleCloseFullscreen} />
                                            <div className="image-navigation">
                                                {imageUrls.length > 1 && (
                                                    <>
                                                        <button className="prev" onClick={handlePrevFullscreenImage}>&#10094;</button>
                                                        <button className="next" onClick={handleNextFullscreenImage}>&#10095;</button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="carousel">
                                            <img
                                                src={imageUrls[currentImageIndex].Location}
                                                alt={`Preview ${currentImageIndex}`}
                                                onClick={() => handleImageClick(currentImageIndex)}
                                            />
                                            <div className="image-navigation">
                                                {imageUrls.length > 1 && (
                                                    <>
                                                        <button className="prev" onClick={handlePrevImage}>&#10094;</button>
                                                        <button className="next" onClick={handleNextImage}>&#10095;</button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="details-section">
                            <div className="section-header">
                                <h2><FaClock /> Trade Information</h2>
                            </div>
                            <div className="trade-info card-details">
                                <p><strong>Open Date:</strong> {formatDate(card.creationDate)}</p>
                                <p><strong>Last Updated:</strong> {formatDate(card.lastUpdated)}</p>
                                <p><strong>Trade Duration:</strong> {isTradeActive ? calculateTradeDuration(card.creationDate, now) : calculateTradeDuration(card.creationDate, card.closedDate)}</p>
                                <p><strong>Trade Type:</strong> {card.type === '1' ? 'Forex' : 'Indices'}</p>
                                <p><strong>Trade Direction:</strong> {card.tradeDirection.toUpperCase()}</p>
                                <p><strong>Trade Status:</strong> {isTradeActive ? 'Ongoing' : 'Closed'}</p>

                            </div>
                            <div className="section-header">
                                <h2><FaMoneyBillWave /> Price Information</h2>
                            </div>
                            <div className="price-info card-details">
                                <div className="price-info-grid">
                                    <div className="price-info-item">
                                        <p><strong>Entry:</strong> {card.entryPrice}</p>
                                    </div>
                                    <div className="price-info-item">
                                        <p><strong>Stop Loss:</strong> {card.stopLoss} {isStopLossTriggered && <span className="triggered">(Triggered)</span>}</p>
                                    </div>
                                    {isTradeClosed && (
                                        <div className="price-info-item">
                                            <p><strong>Exit:</strong> {card.exitPrice}</p>
                                        </div>
                                    )}
                                    {card.exitPrice !== 0 && (
                                        <div className="price-info-item">
                                            <p><strong>{card.assetType === 1 ? 'Pips' : 'Points'} {pipsOrPointsGained > 0 ? "Gained" : "Lost"}:</strong> {pipsOrPointsGained}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="section-header">
                                <h2><FaChartLine /> Take Profit Information</h2>
                            </div>
                            <div className="take-profit-info card-details">
                                {takeProfits[0] !== null && takeProfits.length > 0 ? (
                                    <ul className="take-profit-list">
                                        {takeProfits.map((profit, index) => {
                                            const isReached =
                                                (card.tradeDirection === 'buy' && card.exitPrice >= profit) ||
                                                (card.tradeDirection === 'sell' && card.exitPrice <= profit);
                                            return (
                                                <div>
                                                    {card.exitPrice !== 0 ? (
                                                        <li key={index} className={isReached ? 'reached' : 'unreached'}>
                                                            <b>Take Profit {index + 1}:</b> {profit}
                                                            {card.exitPrice !== 0 ? (
                                                                isReached ? (
                                                                    <span className="reached"> (Executed)</span>
                                                                ) : (
                                                                    <span className="unreached"> (Not executed)</span>
                                                                )
                                                            ) : null}
                                                        </li>
                                                    ) : (
                                                        <li key={index} >
                                                            <b>Take Profit {index + 1}:</b> {profit}

                                                        </li>

                                                    )
                                                    }
                                                </div>


                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <p>No take profit levels provided yet.</p>
                                )}
                            </div>
                            <div className="section-header">
                                <h2><FaComments /> Trader Comments</h2>
                            </div>
                            <div className="trader-comments card-details">
                                <p>{card.adminComment || "No comments provided."}</p>
                            </div>
                        </div>
                    </>
                    
                ) : (
                    <div className="spinner">Loading...</div>
                )
            )}
        </div>
    );
}

export default CardViewPage;
