import React from 'react';
import './LoginPage.css';
import '../../assets/global.css';

import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from "react";


function LoginPage() {

    const navigate = useNavigate();
    const [formStatus, setFormStatus] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://trading-website-5b1479c37ccb.herokuapp.com/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
    
            if (response.ok) {
                sessionStorage.clear();
    
                sessionStorage.setItem('userId', data.userId);
                sessionStorage.setItem('token', data.token); 
                sessionStorage.setItem('role', data.role);   
    
                console.log('Login successful', data);
                navigate('/dashboard');
            } else {
                console.log('Login failed', data);
                setFormStatus({ status: 'error', message: data.message });
            }
        } catch (error) {
            console.error('Error during fetch operation', error);
            setFormStatus({ status: 'error', message: 'Login failed, please try again!' });
        }
    };
    

    return (
        <div className="login-container">
            <div className="login-card">
                <h1 className='login-header'>Login</h1>
                {formStatus && <p className={`form-status ${formStatus.status}`}>{formStatus.message}</p>}

                <form className="login-form" onSubmit={handleSubmit}>
                    <label htmlFor="email" className='login-label'>Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeHolder="Enter your email"
                        className='login-input'
                        required />

                    <label htmlFor="password" className='login-label'>Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        placeHolder="Enter your password"
                        className='login-input'
                        required />

                    <button type="submit" className="login-button">Login</button>
                </form>
                <p className="signup-text">Don't have an account? <NavLink to="/signup" activeClassName="active-link">Sign up</NavLink></p>
                <p className="signup-text"><NavLink to="/forgotPassword" activeClassName="active-link">Forgot password?</NavLink></p>
            </div>
        </div>
    );
}

export default LoginPage;
