import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';


function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 agtradingtrades.com. All rights reserved.</p>
        <nav className="footer-navigation">
        <NavLink to="/privacy-policy" activeClassName="active-link">Privacy Policy</NavLink>
        <NavLink to="/tos" activeClassName="active-link">Terms of Service</NavLink>
        <NavLink to="/contact" activeClassName="active-link">Contact Us</NavLink>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
