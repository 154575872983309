import React from 'react';
import Hero from '../../components/Hero/Hero';

import './HomePage.css';
import '../../assets/global.css';
import AboutPage from '../AboutPage/AboutPage';
import ContactPage from '../ContactPage/ContactPage';

function HomePage() {
    return (
        <div className="home-page">
            <h1>Your Trusted Partner in Trading</h1>
            <Hero />
            <AboutPage />
            <ContactPage    />
        </div>
    );
}

export default HomePage;
