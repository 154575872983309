import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import AboutPage from './pages/AboutPage/AboutPage';
import HomePage from './pages/HomePage/HomePage';
import ContactPage from './pages/ContactPage/ContactPage';
import LoginPage from './pages/LoginPage/LoginPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import CardViewPage from './pages/CardViewPage/CardViewPage';
import NewCardPage from './pages/NewCardPage/NewCardPage';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import EditCardPage from './pages/EditCardPage/EditCardPage';
import EmailVerificationPage from './pages/EmailVerificationPage/EmailVerificationPage';
import TraderProfile from './pages/TraderProfile/TraderProfile';
import NewBlogPost from './pages/NewBlogPost/NewBlogPost';
import SearchTraders from './pages/SearchTraders/SearchTraders';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import ForgotPasswordPage from './pages/SignUpPage/ForgotPasswordPage/ForgotPasswordPage';
import CapitalPlayground from './pages/CapitalPlayground/CapitalPlayground';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermOfServicePage/TermsOfService';
import NewTrader from './pages/NewTraderPage/NewTrader';
import Footer from './components/Footer/Footer';
import FAQ from './pages/FAQPage/FAQ';

function App() {
    return (
        <Router>
        <Header />
        <div className="page-container"> 
            <div className="content-wrap"> 
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/card-view/:cardId" element={<CardViewPage />} />
                    <Route path="/newcard" element={<NewCardPage />} />
                    <Route path="/editcard/:cardId" element={<EditCardPage />} />
                    <Route path="/traderprofile" element={<TraderProfile />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/searchtraders" element={<SearchTraders />} />
                    <Route path="/newblogpost" element={<NewBlogPost />} />
                    <Route path="/payment" element={<PaymentPage />} />
                    <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
                    <Route path="/become-a-trader" element={<NewTrader />} />
                    <Route path="/verify-email/:token" element={<EmailVerificationPage />} />
                    <Route path="/capitalPlayground" element={<CapitalPlayground />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="/tos" element={<TermsOfServicePage />} />
                    <Route path="/FAQ" element={<FAQ />} />

                    <Route path='/404' element={<HomePage />}></Route>
                </Routes>
            </div>
            <Footer />  
        </div>
    </Router>
    
    );
}

export default App;

