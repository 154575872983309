import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TraderProfile.css';
import '../../assets/global.css';
import headshot from "../../assets/images/headshot.jpg";

function TraderProfile({ traderId }) {
    const [traderData, setTraderData] = useState(null);
    const [traderPost, setTraderPost] = useState([]);
    const [tradesData, setTradesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState({
        totalTrades: 0,
        activeTrades: 0,
        closedTrades: 0,
        winningTrades: 0,
        losingTrades: 0,
        totalPointsGained: 0,
        totalPointsLost: 0,
        overallPerformance: 0,
        winRate: 0,
        averageWin: 0,
        averageLoss: 0,
        profitFactor: 0,
        riskRewardRatio: 0,
        numberOfForexPairs: 0,
        numberOfIndices: 0,
        biggestWin: [0, ''],
        biggestLoss: [0, ''],
    });
    const [currentPostIndex, setCurrentPostIndex] = useState(0);

    useEffect(() => {
        const fetchTraderData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/tradersTrades`);
                setTradesData(response.data);

                const traderInfoResponse = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/traderInfo`);
                setTraderData(traderInfoResponse.data[0]);

                const traderPosts = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/traderPosts`);
                setTraderPost(traderPosts.data);

            } catch (err) {
                console.error("Error fetching trader data:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTraderData();
    }, [traderId]);

    useEffect(() => {
        if (tradesData.length > 0) {
            const analyzedStats = analyzeTrades(tradesData);
            setStats(analyzedStats);
        }
    }, [tradesData]);

    const analyzeTrades = (trades) => {
        let winningTrades = 0;
        let losingTrades = 0;
        let totalPointsGained = 0;
        let totalPointsLost = 0;
        let totalTrades = trades.length;
        let activeTrades = 0;
        let closedTrades = 0;
        let numberOfForexPairs = 0;
        let numberOfIndices = 0;
        let biggestWin = [0, ''];
        let biggestLoss = [0, ''];

        trades.forEach(trade => {
            if (trade.type === "1") {
                numberOfForexPairs += 1;
            } else if (trade.type === "2") {
                numberOfIndices += 1;
            }

            if (trade.exitPrice !== 0) {
                if (trade.isActive === 0) {
                    closedTrades += 1;
                    if (trade.tradeDirection === "buy") {
                        if (trade.exitPrice > trade.entryPrice) {
                            winningTrades += 1;
                            totalPointsGained += trade.exitPrice - trade.entryPrice;
                            if (trade.exitPrice - trade.entryPrice > biggestWin[0]) {
                                biggestWin = [trade.exitPrice - trade.entryPrice, trade.symbol]
                            }
                        } else {
                            losingTrades += 1;
                            totalPointsLost += trade.entryPrice - trade.exitPrice;
                            if (trade.entryPrice - trade.exitPrice > biggestLoss[0]) {
                                biggestLoss = [trade.entryPrice - trade.exitPrice, trade.symbol]
                            }
                        }
                    } else {
                        if (trade.exitPrice < trade.entryPrice) {
                            winningTrades += 1;
                            totalPointsGained += trade.entryPrice - trade.exitPrice;
                            if (trade.entryPrice - trade.exitPrice > biggestWin[0]) {
                                biggestWin = [trade.entryPrice - trade.exitPrice, trade.symbol]
                            }
                        } else {
                            losingTrades += 1;
                            totalPointsLost += trade.exitPrice - trade.entryPrice;
                            if (trade.exitPrice - trade.entryPrice > biggestLoss[0]) {
                                biggestLoss = [trade.exitPrice - trade.entryPrice, trade.symbol]
                            }
                        }
                    }
                }
            } else {
                if (trade.isActive === 1) {
                    activeTrades += 1;
                } else {
                    closedTrades += 1;
                }
            }
        });

        const winRate = ((winningTrades / closedTrades) * 100).toFixed(2);
        const averageWin = (winningTrades ? (totalPointsGained / winningTrades) : 0).toFixed(4);
        const averageLoss = (losingTrades ? (totalPointsLost / losingTrades) : 0).toFixed(4);
        const profitFactor = (totalPointsLost ? (totalPointsGained / totalPointsLost) : 0).toFixed(4);
        const riskRewardRatio = (averageLoss ? (averageWin / averageLoss) : 0).toFixed(4);
        const overallPerformance = (totalPointsGained - totalPointsLost).toFixed(4);
        totalPointsGained = totalPointsGained.toFixed(4);
        totalPointsLost = totalPointsLost.toFixed(4);
        activeTrades = totalTrades - closedTrades;

        return {
            winningTrades,
            losingTrades,
            totalPointsGained,
            totalPointsLost,
            overallPerformance,
            totalTrades,
            activeTrades,
            closedTrades,
            winRate,
            averageWin,
            averageLoss,
            profitFactor,
            riskRewardRatio,
            numberOfForexPairs,
            numberOfIndices,
            biggestWin,
            biggestLoss,
        };
    };

    const handleNextPost = () => {
        setCurrentPostIndex((prevIndex) => (prevIndex === traderPost.length - 1 ? 0 : prevIndex + 1));
    };

    const handlePrevPost = () => {
        setCurrentPostIndex((prevIndex) => (prevIndex === 0 ? traderPost.length - 1 : prevIndex - 1));
    };

    if (loading) return <div className="spinner">Loading...</div>;  
    if (error) return <div className="error-message">{error}</div>;  

    return (
        <div className="trader-profile-container">
            {traderData && (
                <div className="trader-basic-info">
                    <h3>Trader Info</h3>
                    <div className="trader-basic-info-container">
                        <div className="trader-basic-info-left">
                            <div className="trader-basic-info-left-image">
                                <image src={headshot} alt="This is the profile picture" className='profile-picture' />
                                <p>
                                    <span>Name:</span> {traderData.username}
                                    <br />
                                    
                                </p>
                            </div>
                        </div>
                        <div className="trader-basic-info-right">
                            
                            {traderPost.length > 0 && (
                <div className="trader-blog-posts">
                <h3>Posts</h3>
                <div className="post-navigation">
                    <div className="post-navigation-item-content">
                        <h4>{traderPost[currentPostIndex].title}</h4>
                        <p>{traderPost[currentPostIndex].content}</p>
                    </div>
                    <div className="post-navigation-buttons">
                        <button className="button-post-prev" onClick={handlePrevPost}>Previous</button>
                        <button className="button-post-next" onClick={handleNextPost}>Next</button>
                    </div>
                </div>
            </div>
            )}
                        </div>
                    </div>
                </div>
            )}

            
            <div className="trader-signals">
                <h3>Trading Stats</h3>
                <div className="trader-stats">
                    <div className="stats-section">
                        <h4>General Stats</h4>
                        <p>
                            <span>Total Trades: </span>
                            {stats.totalTrades}
                        </p>
                        <p>
                            <span>Active Trades: </span>
                            {stats.activeTrades}
                        </p>
                        <p>
                            <span>Closed Trades: </span>
                            {stats.closedTrades}
                        </p>
                        <p>
                            <span>Forex Trades: </span>
                            {stats.numberOfForexPairs}
                        </p>
                        <p>
                            <span>Indices Trades: </span>
                            {stats.numberOfIndices}
                        </p>
                        <p>
                            <span>Biggest Win: </span>
                            {stats.biggestWin[1]}: {stats.biggestWin[0]}
                        </p>
                        <p>
                            <span>Biggest Loss: </span>
                            {stats.biggestLoss[1]}: {stats.biggestLoss[0]}
                        </p>
                    </div>
                    <div className="stats-section">
                        <h4>Performance Stats</h4>
                        <p>
                            <span>Winning Closed Trades: </span> {stats.winningTrades}
                        </p>
                        <p>
                            <span>Losing Closed Trades: </span> {stats.losingTrades}
                        </p>
                        <p>
                            <span>Total Points/Pips Gained: </span>
                            <span className='green-text'>{stats.totalPointsGained}</span>
                        </p>

                        <p>
                            <span>Total Points/Pips Lost: </span>
                            <span className='red-text'>{stats.totalPointsLost}</span>
                        </p>
                        <p>
                            <span>Overall Performance: </span>
                            <span className='green-text'>{stats.overallPerformance}</span>
                        </p>
                        <p>
                            <span>Win Rate: </span> {stats.winRate}%
                        </p>
                    </div>
                    <div className="stats-section">
                        <h4>Risk Metrics</h4>
                        <p>
                            <span>Average Win: </span>
                            {stats.averageWin}
                        </p>
                        <p>
                            <span>Average Loss: </span>
                            {stats.averageLoss}
                        </p>
                        <p>
                            <span>Profit Factor: </span>
                            {stats.profitFactor}
                        </p>
                        <p>
                            <span>Risk-Reward Ratio: </span>
                            {stats.riskRewardRatio}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TraderProfile;
