import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Area, AreaChart
} from 'recharts';
import { NavLink } from 'react-router-dom';
import "./CapitalPlayground.css";

function PageDescription() {
    return (
        <div className="page-description">
            <h3>Welcome to the Capital Playground!</h3>
            <p>
                This tool allows you to visualize how your trading journey could have evolved over time. By analyzing historical trades, you can see how different trading strategies would have impacted your capital.
            </p>
            <h4>Key Features:</h4>
            <ul>
                <li>
                    <strong>Starting Capital:</strong> We recommend starting with a minimum capital of <strong>$1,000</strong> to give you a meaningful experience in capital growth.
                </li>
                <li>
                    <strong>Position Multiplier:</strong> You can choose how many positions to open per trade. Typically, we advise starting with <strong>1-3 positions</strong> to balance risk and potential reward.
                </li>
                <li>
                    <strong>Market Selection:</strong> Choose from various markets, including <strong>NASDAQ100, SP500, and GER40</strong>, to see how each would have performed based on historical data.
                </li>
            </ul>
            <h4>How It Works:</h4>
            <ol>
                <li>Adjust your <strong>starting capital</strong> using the slider.</li>
                <li>Select the number of positions you would like to take with the multiplier control.</li>
                <li>Choose your desired market from the dropdown menu.</li>
                <li>Watch as the graph updates to show your potential capital growth or decline over time, based on the selected parameters.</li>
            </ol>
            <p>
                Use this playground to experiment, learn, and strategize your trading approach!
            </p>
        </div>
    );
}


function DynamicDescription({ startingCapital, market, finalCapital, tradeCount }) {
    return (
        <p style={{ color: '#ccc', fontSize: '1.2rem', marginTop: '1rem' }}>
            If you had started with <strong>${startingCapital}</strong> and followed only
            <strong> {market}</strong> trades, you would have
            <strong> ${finalCapital.toFixed(2)} </strong>
            after {tradeCount} trades.
        </p>
    );
}



function Disclaimer() {
    return (
        <div style={{ color: '#ccc', fontSize: '0.9rem', marginTop: '2rem' }}>
            <p><strong>Disclaimer:</strong> This tool is for educational purposes only and should not be considered financial advice. Past performance is not indicative of future results. Trading involves significant risk and may result in financial loss. Please ensure you understand the risks involved and consult with a financial advisor before making any investment decisions.</p>
            <p>We are not responsible for any financial losses incurred based on the use of this tool.</p>
        </div>
    );
}

function CapitalPlayground() {
    const [startingCapital, setStartingCapital] = useState(1000);
    const [multiplier, setMultiplier] = useState(3);
    const [market, setMarket] = useState('NASDAQ100 CFD');
    const [tradeData, setTradeData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [yAxisDomain, setYAxisDomain] = useState([0, 10000]);
    const token = sessionStorage.getItem('token');
    const [finalCapital, setFinalCapital] = useState(startingCapital); // New state for final capital

    // Fetch trade data
    useEffect(() => {
        const fetchTradeData = async () => {
            const headers = {
                'Authorization': `Bearer ${token}`
            };
            try {
                const tradeData = await axios.get(`https://trading-website-5b1479c37ccb.herokuapp.com/AllTradesNonActive`, { headers });
                setTradeData(tradeData.data);
            } catch (error) {
                console.error('Error fetching trade data', error);
            }
        };

        fetchTradeData();
    }, [token]);

    // Update graph data and final capital
    useEffect(() => {
        if (tradeData.length === 0) return;

        const filteredTrades = tradeData.filter(trade => trade.pair === market);
        let currentCapital = startingCapital;
        let capitalGrowth = [{ tradeNumber: 0, capital: startingCapital }];

        filteredTrades.forEach((trade, index) => {
            const entryPrice = trade.entryPrice;
            const exitPrice = trade.exitPrice;
            let profit = 0;

            if (trade.tradeDirection === "sell") {
                profit = (entryPrice - exitPrice) * multiplier;
            } else if (trade.tradeDirection === "buy") {
                profit = (exitPrice - entryPrice) * multiplier;
            }

            
            currentCapital += profit;

            capitalGrowth.push({
                tradeNumber: `# ${index + 1}`,   
                capital: currentCapital, 
                tradeId: trade.id,
            });
            


        });

        setGraphData(capitalGrowth);
        setFinalCapital(currentCapital);  
        setYAxisDomain([Math.min(0) * 0.9, Math.max(...capitalGrowth.map(d => d.capital)) * 1.1]);

    }, [startingCapital, multiplier, market, tradeData]);


    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <NavLink to={`/card-view/${payload[0].payload.tradeId}`} style={{ textDecoration: 'none' }}>
                    <div className="custom-tooltip" style={{ backgroundColor: '#1e1e1e', padding: '10px', border: '1px solid #ccc', color: '#fff', cursor: 'pointer' }}>
                        <p className="label">{`Trade Number: ${payload[0].payload.tradeNumber}`}</p>
                        <p className="label">{`Capital: $${payload[0].value.toFixed(2)}`}</p>
                        <p className="label">{`Trade ID: ${payload[0].payload.tradeId}`}</p>
                    </div>
                </NavLink>
            );
        }
        return null;
    };

    return (
        <div className="capital-playground" style={{ fontFamily: 'Arial, sans-serif', color: '#fff', backgroundColor: '#121212', padding: '2rem' }}>
            
            <PageDescription />

            <div className="controls" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                <div style={{ marginRight: '1rem' }}>
                    <label>Starting Capital: ${startingCapital}</label>
                    <input
                        type="range"
                        min="100"
                        max="10000"
                        step="100"
                        value={startingCapital}
                        onChange={e => setStartingCapital(Number(e.target.value))}
                    />
                </div>
                <div style={{ marginRight: '1rem' }}>
                    <label>Number of positions per trade: {multiplier}x</label>
                    <input
                        type="range"
                        min="1"
                        max="5"
                        step="1"
                        value={multiplier}
                        onChange={e => setMultiplier(Number(e.target.value))}
                    />
                </div>
                <div>
                    <label>Market: {market}</label>
                    <select value={market} onChange={e => setMarket(e.target.value)} className='sorting-select'>
                        <option value="NASDAQ100 CFD">Nasdaq</option>
                        <option value="SP500 CFD">SP500 CFD</option>
                        <option value="GER40 CFD">GER40 CFD</option>
                    </select>
                </div>
            </div>

            <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={graphData}>
                    <defs>
                        <linearGradient id="colorCapital" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#007BFF" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#007BFF" stopOpacity={0} />
                        </linearGradient>
                    </defs>

                    <XAxis dataKey="tradeNumber" tick={{ fill: '#007BFF' }} />
                    <YAxis domain={yAxisDomain} orientation='right' tick={{ fill: '#007BFF' }} tickFormatter={(value) => `$${value}`}  />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend wrapperStyle={{ color: '#007BFF' }} />
                    <Area type="monotone" dataKey="capital" stroke="#007BFF" fillOpacity={1} fill="url(#colorCapital)" />
                </AreaChart>
            </ResponsiveContainer>

            <DynamicDescription
                startingCapital={startingCapital}
                market={market}
                finalCapital={finalCapital}
                tradeCount={graphData.length - 1}
            />

            <Disclaimer />
        </div>
    );
}

export default CapitalPlayground;
