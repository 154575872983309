import React, { useState } from 'react';
import './FAQ.css';

const faqData = [
  {
    category: 'Account',
    questions: [
      {
        question: 'How do I reset my password?',
        answer: 'To reset your password, go to the login page and click on "Forgot Password." An email will be sent to you with instructions.'
      },
      {
        question: 'I lost access to my email account. What can I do?',
        answer: 'If you lost access to your email account and can no longer receive emails, please contact our support team for assistance.'
      },
      {
        question: 'How do I create a new account?',
        answer: 'To create a new account, click on the "Sign Up" button on the homepage and fill in the required information. You will receive a confirmation email to verify your account.'
      },
      {
        question: 'What should I do if I can’t access my account?',
        answer: 'If you are having trouble accessing your account, please use the "Forgot Password" feature. If the issue persists, contact our support team for assistance.'
      }
    ]
  },
  {
    category: 'Billing',
    questions: [
      {
        question: 'How do I update my payment information?',
        answer: 'For billing, we use the third-party payment processor Stripe. To update your payment information, go to the Billing section of your profile and click on "Update Payment Method."'
      },
      {
        question: 'What is your refund policy?',
        answer: 'We operate on a subscription model to provide trade details. While you can cancel your subscription at any time, refunds are not available for unused portions of a subscription.'
      },
      {
        question: 'I cannot see active trades even though I purchased a subscription. What should I do?',
        answer: 'If you purchased a subscription and do not see active trades, please contact our support team with the same email you used to purchase the subscription and a copy of your receipt.'
      },
      {
        question: 'Can I use a different email address when purchasing a subscription than the one I used to sign up?',
        answer: 'No, you must use the same email address that you used to sign up/log in. Purchasing a subscription with a different email address will result in your account not receiving any benefits. To avoid this, we automatically send the correct email to Stripe. If you still experience issues, please contact our support team.'
      }
    ]
  },
  {
    category: 'Technical Support',
    questions: [
      {
        question: 'Why am I not receiving email notifications?',
        answer: 'Ensure your email address is correct in your profile settings and that notifications are enabled. Also, check your spam or junk folder.'
      },
      {
        question: 'How do I contact support?',
        answer: 'You can reach our support team via the "Contact Us" page or by emailing support@website.com.'
      },
      {
        question: 'What should I do if the website is not loading?',
        answer: 'If the website is not loading, try clearing your browser cache or using a different browser. If the issue persists, check your internet connection or contact support.'
      },
      {
        question: 'How do I report a bug or issue?',
        answer: 'To report a bug, please visit the "Support" section and fill out the bug report form. Include as much detail as possible to help us resolve the issue quickly.'
      }
    ]
  },
  {
    category: 'Trading',
    questions: [
      {
        question: 'What is the Capital Playground feature?',
        answer: 'The Capital Playground allows you to simulate how your capital would have grown based on past trading signals. You can adjust settings like the market, starting capital, and other variables to see potential results.'
      },
      {
        question: 'What are active trades, and why can’t I see any details?',
        answer: 'Active trades are trades that are currently open. To see the details of active trades, you need an active subscription. If you have an active subscription and still can’t see details, please contact our support team.'
      },
      {
        question: 'Do I have access to historical data?',
        answer: 'Yes, even as a free user, you have access to all previous trades made in the past. You can view them in the "Finished Trades" tab.'
      },
      {
        question: 'Can I get alerted when new trades are added?',
        answer: 'Yes, all subscription plans include an alert feature for new trades. Higher-tier plans offer more advanced alerting options. See the pricing page for more information.'
      },
      {
        question: 'How and when are new trades added?',
        answer: 'New trades are added by verified traders who have undergone our verification process, training, and have years of experience. Trades are added regularly, though the frequency may vary based on market conditions, volume, and other factors.'
      },
      {
        question: 'Will I make a profit using your services?',
        answer: 'While we aim to provide valuable trading signals, profit is not guaranteed due to the nature of the financial markets. Trading always involves risk, and we recommend trading responsibly. While our traders aim to minimize the risk of loss, they cannot guarantee profits. You can also check each traders track record to see more details about their performance.'
      }
    ]
  }
];

  
const FaqPage = () => {
  const [openCategory, setOpenCategory] = useState(null);

  const toggleCategory = (index) => {
    setOpenCategory(openCategory === index ? null : index);
  };

  return (
    <div className="faq-page-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      {faqData.map((category, index) => (
        <div key={index} className="faq-category">
          <h2 className="faq-category-title" onClick={() => toggleCategory(index)}>
            {category.category}
            <span className="arrow">{openCategory === index ? '-' : '+'}</span>
          </h2>
          {openCategory === index && (
            <ul className="faq-question-list">
              {category.questions.map((item, qIndex) => (
                <li key={qIndex} className="faq-question">
                  <p className="question">{item.question}</p>
                  <p className="answer">{item.answer}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default FaqPage;
